const { REACT_APP_BACKEND_TRAILBLAZER_URL, REACT_APP_BACKEND_CLINICAL_URL } = process.env
import { axiosCall, createErrorNotification } from '../Common/services/helpers/helpers'

import { store } from '../../domain/store'

const axios = require('axios').default

const getToken = () => {
  const token = store?.getState()?.settings?.googleToken
  return token ? token : ''
}

export const getAnalyses = async (
  search = '',
  workflow = '',
  orderId = null,
  pagination,
  filters,
  sorter,
  includeHidden
): Promise<any> => {
  const { current, pageSize } = pagination
  const queryParams = new URLSearchParams({
    workflow: workflow,
    pageSize: pageSize.toString(),
    page: current.toString(),
  })

  // Handle search
  if (search) {
    queryParams.append('search', search)
  }

  // Handle sorter
  if (sorter && sorter.field && sorter.order) {
    queryParams.append('sortField', sorter.field)
    queryParams.append('sortOrder', sorter.order === 'ascend' ? 'asc' : 'desc')
  }

  // Handle order filtering
  if (orderId) {
    queryParams.append('orderId', orderId)
  }

  // Handle filters
  Object.entries(filters).forEach(([key, value]) => {
    if (key === 'comment' && Array.isArray(value)) {
      queryParams.append('hasComment', value.includes('true') ? 'true' : 'false')
    } else if (key === 'is_delivered' && Array.isArray(value)) {
      queryParams.append('delivered', value.includes('true') ? 'true' : 'false')
    } else if (Array.isArray(value)) {
      value.forEach((value) => queryParams.append(`${key}[]`, value.toString()))
    }
  })

  // Handle includeHidden
  if (includeHidden) {
    queryParams.append('includeHidden', includeHidden)
  }

  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/analyses?${queryParams.toString()}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getFailedStats = async (): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/aggregate/jobs`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getAnalysis = async (analysisId: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/analyses/${analysisId}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const editAnalysis = async (
  token: string = getToken(),
  analysisId: string,
  type: string,
  isVisible?: boolean,
  comment?: string,
  status?: string,
  delivered?: boolean
): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/analyses/${analysisId}`

  const body =
    type === 'comment'
      ? { comment: comment }
      : type === 'status'
      ? { status: status?.toLowerCase() }
      : type === 'delivered'
      ? { is_delivered: delivered }
      : { is_visible: isVisible }

  return new Promise((resolve, reject) => {
    axios
      .put(endPoint, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const cancelAnalysis = async (analysisId: number): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/analyses/${analysisId}/cancel`
  const token = getToken()
  const body = {}
  const headers = { headers: { Authorization: `Bearer ${token}` } }
  return new Promise((resolve, reject) => {
    axios
      .post(endPoint, body, headers)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const editOrder = async (
  token: string = getToken(),
  orderId: string,
  open?: boolean
): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/orders/${orderId}/open`
  const body = { open }
  return new Promise((resolve, reject) => {
    axios
      .patch(endPoint, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const patchMultipleAnalyses = async (
  token: string = getToken(),
  analysis: any
): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/analyses`
  const body = analysis
  return new Promise((resolve, reject) => {
    axios
      .patch(endPoint, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
        createErrorNotification(error)
      })
  })
}

export const getCase = async (family: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/cases?enquiry=${family}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getSample = async (name: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/samples/${name}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getCaseDeliveryMessage = async (name: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/cases/${name}/delivery_message`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getCasesDeliveryMessage = async (caseIds: string[]): Promise<any> => {
  const caseIdsString = caseIds.join(',')
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/cases/delivery_message?case_ids=${caseIdsString}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getOrderDeliveryMessage = async (order_id: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/orders/${order_id}/delivery_message`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getOrders = async (
  workflow: string,
  search: string,
  pagination,
  filters,
  sorter
): Promise<any> => {
  const { current, pageSize } = pagination
  const queryParams = new URLSearchParams({
    workflow: workflow,
    search: search,
    pageSize: pageSize,
    page: current,
  })

  if (sorter && sorter.field && sorter.order) {
    queryParams.append('sortField', sorter.field)
    queryParams.append('sortOrder', sorter.order === 'ascend' ? 'asc' : 'desc')
  }

  Object.entries(filters).forEach(([key, value]) => {
    if (key === 'is_delivered' && Array.isArray(value)) {
      queryParams.append('delivered', value.includes(true) ? 'true' : 'false')
    }
  })
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/orders?${queryParams.toString()}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const getOrder = async (orderId: string): Promise<any> => {
  const endPoint = `${REACT_APP_BACKEND_CLINICAL_URL}/orders/${orderId}`
  return axiosCall(axios.get.bind(null, endPoint))
}

export const exchangeAuthorizationCode = async (code: string): Promise<string> => {
  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/auth`
  const body = { code }
  const response = await axios.post(endPoint, body)
  return response.data.token
}

export const refreshToken = async (): Promise<string> => {
  const token = getToken()
  const headers = { Authorization: `Bearer ${token}` }
  const endPoint = `${REACT_APP_BACKEND_TRAILBLAZER_URL}/auth/refresh`
  const response = await axios.get(endPoint, { headers: headers })
  return response.data.token
}
