import { getCasesDeliveryMessage, patchMultipleAnalyses } from '../../services'
import { createNotification } from '../../../Common/services/helpers/helpers'

export type BulkActionType = 'visibility' | 'status' | 'comment' | 'deliveryMessage' | 'delivered'
type NotificationType = 'success' | 'error' | 'warning'

interface Analysis {
  id: string
  is_visible?: boolean
  status?: string
  comment?: string
  is_delivered?: boolean
}

interface PatchResponse {
  analyses: Analysis[]
}

export const performBulkAction = (
  type: BulkActionType,
  value: boolean | string,
  ids: string[],
  caseIds: string[] | null = null
): Promise<void | Analysis[]> => {
  switch (type) {
    case 'visibility':
      return performBulkVisibilityUpdate(ids, value as boolean)
    case 'status':
      return performBulkStatusUpdate(ids, value as string)
    case 'comment':
      return performBulkAddComment(ids, value as string)
    case 'deliveryMessage':
      return performDeliveryMessageCopy(caseIds as string[])
    case 'delivered':
      return performBulkDeliveredUpdate(ids, value as boolean)
    default:
      console.error('Invalid action type:', type)
      return Promise.reject()
  }
}

export const performBulkVisibilityUpdate = async (
  ids: string[],
  isVisible: boolean
): Promise<Analysis[] | void> => {
  try {
    const analyses: Analysis[] = ids.map((id) => ({
      id,
      is_visible: isVisible,
    }))
    const response: PatchResponse = await patchMultipleAnalyses(undefined, { analyses })

    let notificationMessage = ''
    if (ids.length === 1) {
      notificationMessage += `Selected analysis is now ${isVisible ? 'visible' : 'hidden'}`
    } else {
      notificationMessage += `Selected analyses are now ${isVisible ? 'visible' : 'hidden'}`
    }

    createNotification('success', 'Visibility Updated', notificationMessage)
    return response.analyses
  } catch (error) {
    console.error('Error updating visibility:', error)
  }
}

export const performBulkStatusUpdate = async (
  ids: string[],
  status: string
): Promise<Analysis[] | void> => {
  try {
    const analyses: Analysis[] = ids.map((id) => ({
      id,
      status,
    }))
    const response: PatchResponse = await patchMultipleAnalyses(undefined, { analyses })
    let notificationMessage = ''
    if (ids.length === 1) {
      notificationMessage += `Selected analysis is now marked as ${status}`
    } else {
      notificationMessage += `Selected analyses are now marked as ${status}`
    }
    createNotification('success', 'Status Updated', notificationMessage)
    return response.analyses
  } catch (error) {
    console.error('Error updating status:', error)
  }
}

export const performBulkAddComment = async (
  ids: string[],
  comment: string
): Promise<Analysis[] | void> => {
  try {
    const analyses: Analysis[] = ids.map((id) => ({
      id,
      comment,
    }))
    const response: PatchResponse = await patchMultipleAnalyses(undefined, { analyses })
    createNotification('success', 'Comment Added', 'Comment has been added to selected analyses')
    return response.analyses
  } catch (error) {
    console.error('Error adding comment:', error)
  }
}

export const performDeliveryMessageCopy = async (caseIds: string[]): Promise<void> => {
  try {
    const response = await getCasesDeliveryMessage(caseIds)
    if (response.message) {
      await navigator.clipboard.writeText(response.message)
      createNotification(
        'success',
        'Message Copied',
        'The delivery message has been copied to the clipboard'
      )
    }
  } catch (error) {
    console.error('Error copying message:', error)
  }
}

const performBulkDeliveredUpdate = async (
  ids: string[],
  isDelivered: boolean
): Promise<Analysis[] | void> => {
  try {
    const analyses = ids.map((id) => ({
      id,
      is_delivered: isDelivered,
    }))
    const response: PatchResponse = await patchMultipleAnalyses(undefined, { analyses })
    let notificationMessage = ''
    const status = isDelivered ? 'delivered' : 'not delivered'
    if (ids.length === 1) {
      notificationMessage += `Selected analysis is now marked as ${status}`
    } else {
      notificationMessage += `Selected analyses are now marked as ${status}`
    }
    createNotification('success', 'Delivery Updated', notificationMessage)
    return response.analyses
  } catch (error) {
    console.error('Error updating delivered:', error)
  }
}

export const isWorkflowDeliverable = (workflow: string[]): boolean => {
  return workflow.every(
    (workflow) =>
      workflow === 'RSYNC' ||
      workflow === 'DEMULTIPLEX' ||
      workflow === 'SPRING' ||
      workflow === 'MIP-RNA'
  )
}
